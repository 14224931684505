import * as React from "react";

import Layout from "../components/layout";
import { AllPhotos } from "../components/photo";
import Seo from "../components/seo";

const PhotoGallery = (props) => (
  <Layout>
    <Seo title="photos" />
    <tr>
      <td colSpan={2}>
        <div className="photoGallery">
          <AllPhotos />
        </div>
      </td>
    </tr>
  </Layout>
);

export default PhotoGallery;
